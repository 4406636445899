import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { UiSchema, ArrayFieldTemplateProps, RJSFSchema } from '@rjsf/utils'
import { ComponentType } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Typography } from '@material-ui/core'

interface SchemaCache {
    definitions: Record<string, RJSFSchema>
    properties: Record<string, RJSFSchema>
}

/**
 * Custom Accordion component for array fields
 */
const ArrayFieldAccordion = (props: ArrayFieldTemplateProps) => {
    const { registry, title } = props
    const DefaultArrayFieldTemplate = registry.templates.ArrayFieldTemplate

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
                <Typography variant="h6">{title || ''}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <DefaultArrayFieldTemplate {...props} />
            </AccordionDetails>
        </Accordion>
    )
}

/**
 * Type guard for RJSFSchema
 */
const isRJSFSchema = (value: unknown): value is RJSFSchema => {
    return typeof value === 'object' && value !== null
}

/**
 * Creates a schema cache from parent and current schema
 */
const createSchemaCache = (schema: RJSFSchema, parentSchema: SchemaCache): SchemaCache => {
    const mergeSchemaSection = (
        parent: Record<string, RJSFSchema>,
        current: Record<string, RJSFSchema> = {}
    ): Record<string, RJSFSchema> => {
        return Object.entries({ ...parent, ...current }).reduce((acc, [key, value]) => {
            if (isRJSFSchema(value)) {
                acc[key] = value
            }
            return acc
        }, {} as Record<string, RJSFSchema>)
    }
    return {
        definitions: mergeSchemaSection(
            parentSchema.definitions as Record<string, RJSFSchema>,
            schema.definitions as Record<string, RJSFSchema>
        ),
        properties: mergeSchemaSection(
            parentSchema.properties as Record<string, RJSFSchema>,
            schema.properties as Record<string, RJSFSchema>
        )
    }
}

/**
 * Checks if a field is an array type
 */
const isArrayField = (key: string, schema: RJSFSchema, schemaCache: SchemaCache): boolean => {
    const field = schema.properties?.[key]
    if (!field) return false

    if (isRJSFSchema(field)) {
        if (field.type === 'array') return true

        const refKey = field['$ref']?.split('/').pop()
        if (refKey) {
            const definition = schemaCache.definitions[refKey]
            return isRJSFSchema(definition) && definition.type === 'array'
        }
    }

    return false
}

/**
 * Processes a single field in the schema
 */
const processField = (
    key: string,
    field: RJSFSchema,
    processedUiSchema: UiSchema,
    schemaCache: SchemaCache
): UiSchema => {
    if (field.type === 'object') {
        return wrapAccordionAroundArrayFields(
            field,
            (processedUiSchema[key] as UiSchema) || {},
            schemaCache
        )
    }

    if (field.type === 'array' && field.items) {
        return {
            ...((processedUiSchema[key] as UiSchema) || {}),
            items: wrapAccordionAroundArrayFields(
                field.items as RJSFSchema,
                (processedUiSchema[key] as UiSchema)?.items || {},
                schemaCache
            )
        }
    }

    if (field.$ref) {
        const refKey = field.$ref.split('/').pop()
        if (refKey && schemaCache.definitions[refKey]) {
            return wrapAccordionAroundArrayFields(
                schemaCache.definitions[refKey],
                (processedUiSchema[key] as UiSchema) || {},
                schemaCache
            )
        }
    }

    return (processedUiSchema[key] as UiSchema) || {}
}

/**
 * Wraps array fields in an accordion component
 */
export const wrapAccordionAroundArrayFields = (
    schema: RJSFSchema,
    initialUiSchema: UiSchema = {},
    parentSchema: SchemaCache = { definitions: {}, properties: {} }
): UiSchema => {
    if (!schema?.properties) return initialUiSchema

    // Keeps the previous schemas so we can use them for references and to figure out field types.
    const schemaCache = createSchemaCache(schema, parentSchema)
    const processedUiSchema = { ...initialUiSchema }

    Object.entries(schema.properties).forEach(([key, field]) => {
        if (isRJSFSchema(field)) {
            if (isArrayField(key, schema, schemaCache)) {
                processedUiSchema[key] = {
                    ...(processedUiSchema[key] || {}),
                    'ui:ArrayFieldTemplate':
                        ArrayFieldAccordion as ComponentType<ArrayFieldTemplateProps>
                }
            }

            processedUiSchema[key] = {
                ...processedUiSchema[key],
                ...processField(key, field, processedUiSchema, schemaCache)
            }
        }
    })

    return processedUiSchema
}
